import React from 'react'
import { Link } from 'gatsby'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default () => {
  const { t, i18n } = useTranslation()
  const { changeLanguage } = useI18next()

  const langButton = () => {
    if (i18n.language === 'en') {
      return (
        <Link
          to="#"
          onClick={() => {
            changeLanguage('fr')
          }}
          style={{ textDecoration: 'none' }}
        >
          {t('french')}
        </Link>
      )
    } else {
      return (
        <Link
          to="#"
          onClick={() => {
            changeLanguage('en')
          }}
        >
          {t('english')}
        </Link>
      )
    }
  }

  return (
    <header id="header" className="alt">
      <Link to="/" className="logo">
        <strong>Levo</strong> <span>{t('mobileApp')}</span>
      </Link>
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          paddingRight: '1em',
        }}
      >
        {langButton()}
      </div>
    </header>
  )
}
